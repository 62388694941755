:root {
  --z-index-0: 0;
  --z-index-1: 1;
  --z-index-2: 2;
  --z-index-3: 3;
  --z-index-4: 4;
  --z-index-999: 999;

  --font-family: 'Nunito';
  --store-primary-color: #00489a;
  --store-secondary-color: #c9e7ff;
  --store-text-primary-btn: #ffffff;
  --store-text-secondary-btn: #00489a;
  --store-text-color: #404040;
  --store-danger-color: #fa5c7c;

  --text-sold-out: 'Sold Out';
}

$white: #FFF;
$gold: #FFE58F;
$neutral: #262626;
$white-alpha: #ffffff50;
$gray-alpha: #dde5e950;
$light-gray:#bfbfbf;
$gray:#dde5e9;
$error:#c44345;
$red:#ff0000;
$geek-blue: #F0F5FF;
$dark-red: #8C0000;
$background-geek-blue: #F6F9FF;
$black: #404040;
$blue: #00489A;
$dark-grey: #072446;
$yellow: #f7cb74;
$heavy-red: #FB4141;
$grey: #828282;
$background-yellow: rgba(247, 204, 116, 0.50);
$background-heavy-red: rgba(251, 65, 65, 0.50);

$gray1: #f3f4f8;
$gray2: #bfbfbf;
$gray3: #DDE5E9;
$subtle-gray: #F3F4F8;
$dark-gray: #BFBFBF;
$gostwhite: ghostwhite;

$bg-yellow-50: #F7CC7480;
$subtle-red: #E28173;

