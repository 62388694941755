/* You can add global styles to this file, and also import other style files */
@use './assets/styles/_variable.scss' as *;

* {
  margin: 0px;
  padding: 0px;
}

// Global Style 
.required {
  color: red;
  margin-left: 2px;
}
